<template>
  <div>
    <LoginForm />
  </div>
</template>

<route>
{
  "meta": {
    "guest": true
  }
}
</route>

<script>
import LoginForm from '../components/LoginForm'

export default {
  name: 'Login',
  layout: 'centered',
  components: { LoginForm },
  head() {
    return {
      title: this.$t('login.title'),
    }
  },
}
</script>



<style>

</style>
