<template>
  <v-card elevation="15">
    <ValidationObserver
      ref="observer"
      v-slot="{}"
    >
      <v-form
        class="pt-3"
        @submit.prevent="login"
      >
        <v-card-text class="px-6 pb-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="name"
            rules="required|min:3"
          >
            <v-text-field
              v-model="name"
              outlined
              :label="$t('login.name_label')"
              name="name"
              prepend-icon="mdi-account"
              type="text"
              :disabled="busy || disabled"
              :error-messages="errors"
              required
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            rules="required"
          >
            <v-text-field
              v-model="password"
              outlined
              :label="$t('login.password_label')"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
              :disabled="busy || disabled"
              :error-messages="errors"
              required
              @keyup.enter.prevent="login"
            />
          </ValidationProvider>
          <v-alert
            v-if="message"
            :type="messageType"
            text
          >
            {{ message }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="px-6 pb-6 pt-2">
          <v-btn
            :loading="busy"
            :disabled="busy || disabled"
            depressed
            block
            color="primary"
            large
            type="submit"
          >
            {{ $t('login.login') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'LoginForm',
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      busy: false,
      disabled: false,
      email: '',
      name: '',
      password: '',
      message: null,
      messageType: null,
    }
  },
  created () {
    if (this.$route.query.password) {
      this.password = this.$route.query.password
    }

    if (this.$route.query?.email) {
      this.email = this.$route.query.email
    }

    if (this.$route.query?.name) {
      this.name = this.$route.query.name
    }

    if (this.$route.query?.autologin) {
      this.login()
    }
  },
  watch:  {
    '$socket.connected' (val) {
      if (val) {
        // this.$router.push(this.$route.params?.redirect || '/')
      }
    }
  },
  methods: {
    async login() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.busy = true
      try {
        await this.$store.dispatch('auth/login', {
          name: this.name,
          password: this.password,
        })
        this.$router.push(this.$route.params?.redirect || '/')
      } catch (e) {
        this.messageType = 'error'
        this.message = e.response?.data?.message || e.message || e
      } finally {
        this.busy = false
      }
    },
  },
}
</script>
